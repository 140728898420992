import React from 'react';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import { ValidationTextFieldInput } from '@fingo/lib/components/inputs';

const EmailCollapsableComponent = ({ currentStep, formInput, formChange }) => (
  <Collapse in={currentStep !== 2} sx={{ width: '100%' }}>
    <ValidationTextFieldInput
      name="email"
      type="text"
      label="Email"
      variant="outlined"
      fullWidth
      value={formInput.email}
      disabled={currentStep === 1}
      onChange={(event) => formChange('email', event)}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        style: {
          borderRadius: '30px',
        },
      }}
    />
  </Collapse>
);

EmailCollapsableComponent.propTypes = {
  currentStep: PropTypes.number.isRequired,
  formInput: PropTypes.shape({ email: PropTypes.string }).isRequired,
  formChange: PropTypes.func.isRequired,
};

export default EmailCollapsableComponent;
