import React from 'react';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const AceptaCollapsableComponent = ({ selectedTab, toggleAceptaAuthOpen }) => (
  <Collapse in={selectedTab === 'signIn'} sx={{ width: '100%' }}>
    <Stack
      alignItems="center"
      spacing={1.5}
      mx="auto"
    >
      <Button
        variant="contained"
        size="small"
        id="login-acepta"
        sx={{ borderRadius: 30,
          backgroundColor: '#21344A' }}
        fullWidth
        onClick={toggleAceptaAuthOpen}
      >
        Ingresar con Acepta
      </Button>
    </Stack>
  </Collapse>
);

AceptaCollapsableComponent.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  toggleAceptaAuthOpen: PropTypes.func.isRequired,
};

export default AceptaCollapsableComponent;
