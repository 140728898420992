import React, { useState, useMemo } from 'react';
import Proptypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useIsLogged, useSnackBars, useBooleanState, useStepper, useDynamicFingoLogo } from '@fingo/lib/hooks';
import useNationalTaxServiceCredentialsInput from '@fingo/lib/hooks/useNationalTaxServiceCredentialsInput';
import NationalTaxServiceLogoAndText from '@fingo/lib/components/text/NationalTaxServiceLogoAndText';
import makeStyles from '@mui/styles/makeStyles';
import { Redirect, useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Cookies from 'universal-cookie';
import { validEmail, isPasswordValid } from '@fingo/lib/helpers';
import { REGISTER_USER, CHECK_USERNAME_EMAIL } from '@fingo/lib/graphql';
import { isLoggedInQuery } from '@fingo/lib/apollo/cache/localQuery';
import TermsAndConditionsDialog from '@fingo/lib/components/dialogs/TermsAndConditionsDialog';
import useLogin from '../../hooks/useLogin';
import HeaderCollapsableComponent from './HeaderCollapsableComponent';
import GoogleSign from '../../components/oauth/GoogleSign';
import EmailCollapsableComponent from './EmailCollapsableComponent';
import {
  SignInPasswordCollapsableComponent,
  AceptaCollapsableComponent,
  LostPasswordCollapsableComponent,
} from './SingIn';
import {
  SignUpSiiFieldsCollapsableComponent,
  SignUpFieldsCollapsableComponent,
} from './SignUp';
import LoginTabsComponent from './LoginTabsComponent';
import AceptaAuth from '../../components/dialogs/AceptaAuth';
import PasswordRecovery from '../../components/dialogs/PasswordRecovery';

const useStyles = makeStyles(() => ({
  align: {
    textAlign: 'center',
  },
  logo: {
    width: 180,
  },
}));

const LoginLanding = ({ paperSx, logo, redirectTo, landingTag }) => {
  const [termsOpen, setTermsOpen] = useState(false);
  const { t } = useTranslation();
  const cookies = new Cookies();
  const classes = useStyles();
  const location = useLocation();
  const utmCampaign = cookies.get('utm_campaign') || null;
  const params = new URLSearchParams(location.search);
  const redirectLocation = redirectTo || '/app/home';
  const isLogged = useIsLogged(isLoggedInQuery);
  const fingoLogo = logo || useDynamicFingoLogo();
  const [aceptaAuthOpen, toggleAceptaAuthOpen] = useBooleanState(params.get('acepta') === 'true');
  const [openPasswordRecovery, toggleOpenPasswordRecovery] = useBooleanState();
  const { addAlert } = useSnackBars();
  const loginType = {
    signup: 'signUp',
    signin: 'signIn',
  }[params.get('loginType')];
  const [selectedTab, setSelectedTab] = useState(loginType ?? 'signIn');
  const [formInput, setFormInput] = useState({ email: '', password: '', phone: '', firstName: '', lastName: '' });

  const { currentStep, goNextStep, setCurrentStep } = useStepper();
  const [loginGql, loadingLogin, errorForm] = useLogin(
    formInput.email.toLowerCase(),
    formInput.password,
  );
  const {
    nationalIdentifier, password, onChangeInput, isValid,
  } = useNationalTaxServiceCredentialsInput(null, () => {}, () => {});
  const continueButtonLabel = useMemo(() => {
    if (currentStep === 1 && selectedTab === 'signIn') return 'Ingresar';
    if (currentStep === 1 && selectedTab === 'signUp') return 'Registrarse';
    return 'Siguiente';
  }, [selectedTab, currentStep]);
  const disableNextButton = useMemo(() => {
    if (currentStep === 0) return !validEmail(formInput.email);
    if (currentStep === 1 && selectedTab === 'signIn') return !validEmail(formInput.email) || !formInput?.password;
    if (currentStep === 1 && selectedTab === 'signUp') {
      return (
        !validEmail(formInput.email)
        || !isPasswordValid(formInput.password)
        || !(formInput.phone.length === t('Phone Number Length'))
        || !formInput.firstName
        || !formInput.lastName
      );
    }
    if (currentStep === 2) return !isValid || !password;
    return true;
  }, [currentStep, formInput, nationalIdentifier, password]);

  const formChange = (variable, event) => {
    setFormInput((prev) => ({ ...prev, [variable]: event.target.value }));
  };

  const [registerUser, { loading: loadingRegisterUser }] = useMutation(REGISTER_USER, {
    variables: {
      email: formInput.email.toLowerCase(),
      firstName: formInput.firstName,
      lastName: formInput.lastName,
      password: formInput.password,
      phoneNumber: `+${t('Phone prefix')}${formInput.phone}`,
      utmCampaign,
    },
    onError: (error) => {
      addAlert({
        id: 'register failed',
        message: error.message,
        severity: 'error',
        color: 'error',
      });
    },
    onCompleted: () => loginGql(),
  });

  const continueButtonId = useMemo(() => {
    if (currentStep === 1 && selectedTab === 'signIn') return 'login-button';
    if (currentStep === 1 && selectedTab === 'signUp') return 'register-button';
    return 'continue-button';
  }, [currentStep, selectedTab]);

  const [
    CheckUsernameEmail, { loading: loadingCheckUsername },
  ] = useLazyQuery(CHECK_USERNAME_EMAIL, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ checkUsernameEmail }) => {
      if (!checkUsernameEmail) {
        if (selectedTab === 'signIn') {
          addAlert({
            id: 'no-user-associated',
            message: 'Bienvenido. Registra tus datos para acceder a Fingo',
            color: 'success',
            severity: 'success',
          });
        }
        setSelectedTab('signUp');
      } else {
        if (selectedTab === 'signUp') {
          addAlert({
            id: 'user-already-registered',
            message: 'Bienvenido. Inicia Sesión para acceder a Fingo',
            color: 'success',
            severity: 'success',
          });
        }
        setSelectedTab('signIn');
      }
      goNextStep();
    },
  });

  if (isLogged) return <Redirect to={redirectLocation} />;
  return (
    <>
      <Paper
        sx={paperSx}
        elevation={4}
      >
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Box paddingBottom={1}>
            <img src={fingoLogo} alt="logo" className={classes.logo} />
          </Box>
          <Collapse in={currentStep === 2} orientation="horizontal">
            <NationalTaxServiceLogoAndText text="" />
          </Collapse>
        </Stack>
        <LoginTabsComponent
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setCurrentStep={setCurrentStep}
        />
        <Box>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              if (currentStep === 0) {
                CheckUsernameEmail(
                  { variables: { email: formInput.email, landingTag, utmCampaign } },
                );
              } else if (currentStep === 1 && selectedTab === 'signIn') {
                loginGql();
              } else if (currentStep === 1 && selectedTab === 'signUp') {
                registerUser();
              }
            }}
            id="signInForm"
          >
            <Stack
              alignItems="center"
              spacing={1.5}
              sx={{ width: { xs: '70%', sm: 280 } }}
              mx="auto"
            >
              <HeaderCollapsableComponent
                selectedTab={selectedTab}
                filterBy="signIn"
                currentStep={currentStep}
              />
              <HeaderCollapsableComponent
                selectedTab={selectedTab}
                filterBy="signUp"
                currentStep={currentStep}
              />
              <EmailCollapsableComponent
                currentStep={currentStep}
                formInput={formInput}
                formChange={formChange}
              />
              <SignInPasswordCollapsableComponent
                currentStep={currentStep}
                selectedTab={selectedTab}
                formInput={formInput}
                errorForm={errorForm}
                formChange={formChange}
              />
              <SignUpFieldsCollapsableComponent
                currentStep={currentStep}
                selectedTab={selectedTab}
                formInput={formInput}
                formChange={formChange}
                errorForm={errorForm}
              />
              <SignUpSiiFieldsCollapsableComponent
                currentStep={currentStep}
                selectedTab={selectedTab}
                setTermsOpen={setTermsOpen}
                nationalIdentifier={nationalIdentifier}
                password={password}
                onChangeInput={onChangeInput}
              />
              <LostPasswordCollapsableComponent
                selectedTab={selectedTab}
                toggleOpenPasswordRecovery={toggleOpenPasswordRecovery}
              />
              <LoadingButton
                variant="contained"
                color="primary"
                size="small"
                type="submit"
                id={continueButtonId}
                sx={{ borderRadius: 30,
                  backgroundColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  } }}
                disabled={disableNextButton}
                loading={loadingRegisterUser || loadingLogin || loadingCheckUsername}
                fullWidth
              >
                {continueButtonLabel}
              </LoadingButton>
              <AceptaCollapsableComponent
                selectedTab={selectedTab}
                toggleAceptaAuthOpen={toggleAceptaAuthOpen}
              />
              <Divider sx={{ width: '100%' }}>ó</Divider>
              <GoogleSign label="Ingresar con Google" />
            </Stack>
            <AceptaAuth
              open={aceptaAuthOpen}
              onClose={toggleAceptaAuthOpen}
            />
            <PasswordRecovery
              open={openPasswordRecovery}
              onClose={toggleOpenPasswordRecovery}
            />
          </form>
        </Box>
      </Paper>
      <TermsAndConditionsDialog
        open={termsOpen}
        setOpen={setTermsOpen}
      />
    </>
  );
};

LoginLanding.propTypes = {
  paperSx: Proptypes.shape.isRequired,
  logo: Proptypes.string,
  redirectTo: Proptypes.string,
  landingTag: Proptypes.string,
};

LoginLanding.defaultProps = {
  logo: null,
  redirectTo: null,
  landingTag: null,
};

export default LoginLanding;
