import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';

const HeaderCollapsableComponent = ({ selectedTab, filterBy, currentStep }) => {
  const { t } = useTranslation();
  const headerLabel = useMemo(() => {
    if (selectedTab === 'signIn') {
      if (currentStep === 0) return 'Ingresa tu email para ingresar a tu cuenta';
      if (currentStep === 1) return 'Ingresa tu contraseña';
    } else {
      if (currentStep === 0) return 'Ingresa tu email para registrarte';
      if (currentStep === 1) return 'Ingresa los siguientes datos';
      if (currentStep === 2) return `Ingresa tus credenciales ${t('National Tax Service acronym')} para registrarte`;
    }
    return 'Falta label';
  }, [selectedTab, currentStep]);
  return (
    <Collapse
      in={selectedTab === filterBy}
      sx={{
        width: '100%',
      }}
    >
      <MobileStepper
        variant="dots"
        position="static"
        steps={2}
        activeStep={currentStep}
        sx={{
          pt: 3,
          justifyContent: 'center',

        }}
      />
      <Typography align="center">
        {headerLabel}
      </Typography>
    </Collapse>
  );
};

HeaderCollapsableComponent.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  filterBy: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default HeaderCollapsableComponent;
