import React from 'react';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

const LostPasswordCollapsableComponent = (
  { selectedTab, toggleOpenPasswordRecovery },
) => (
  <Collapse in={selectedTab === 'signIn'} sx={{ width: '100%' }}>
    <Stack
      alignItems="center"
      spacing={1.5}
      mx="auto"
    >
      <Link
        underline="hover"
        variant="body2"
        sx={{
          color: 'text.primary',
          fontSize: 12,
        }}
        onClick={toggleOpenPasswordRecovery}
        id="login-forgot"
      >
        ¿Olvidaste tu contraseña?
      </Link>
    </Stack>
  </Collapse>
);

LostPasswordCollapsableComponent.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  toggleOpenPasswordRecovery: PropTypes.func.isRequired,
};

export default LostPasswordCollapsableComponent;
