import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const LoginTabsComponent = ({ selectedTab, setSelectedTab, setCurrentStep }) => (
  <Tabs
    value={selectedTab}
    onChange={(_, val) => {
      setCurrentStep(0);
      setSelectedTab(val);
    }}
    TabIndicatorProps={{
      style: { display: 'none' },
    }}
    centered
    sx={{
      minHeight: '30px',
      height: '30px',
      overflow: 'visible',
      position: 'relative',
      '& .MuiTabs-flexContainer': {
        '& button': {
          fontSize: '13px',
          borderRadius: 10,
          backgroundColor: '#F2F2F2',
          minHeight: '30px',
          height: '30px',
          width: 150,
          zIndex: 1,
          transition: 'transform 0.4s, margin 0.4s',
        },
        '& button:first-of-type': {
          transform: selectedTab === 'signIn' ? 'translateX(10%)' : 'translateX(0)',
          marginRight: selectedTab === 'signIn' ? '0' : '10px',
        },
        '& button:last-of-type': {
          transform: selectedTab === 'signUp' ? 'translateX(-10%)' : 'translateX(0)',
          marginLeft: selectedTab === 'signUp' ? '-20px' : '0',
        },
      },
      '& button:hover': {
        backgroundColor: 'primary.light',
      },
      '& button.Mui-selected': {
        backgroundColor: 'primary.main',
        color: 'primary.light',
        zIndex: 2,
      },
    }}
  >
    <Tab label="INGRESAR" value="signIn" />
    <Tab label="CREAR CUENTA" value="signUp" />
  </Tabs>
);

LoginTabsComponent.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

export default LoginTabsComponent;
